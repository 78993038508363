import React, { Component } from "react";
import CustomerCard from './customerCard';

import { Element, scroller } from 'react-scroll'

class CustomerCardTable extends Component {

  sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  render() {
    const { data, idTag="_id", village, scroll } = this.props;

    if(scroll && data.length>0) {
      this.sleep(500).then(() => {
      scroller.scrollTo(scroll, {
          duration: 1000,
          smooth: true
        })
      })
    }

    return (
      <div className="row">
        {data && data.map( item => {
          return(<div key={item[idTag]} className="col-12 col-md-6">
              <Element name={item.addrindex}><CustomerCard item={item} village={village} /></Element>
          </div>)
        })}
      </div>
    );
  }
}

export default CustomerCardTable;
