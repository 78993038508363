import React, { Component } from "react";
import VillageCard from './villageCard';

class VillageCardTable extends Component {

  render() {
    const { data, handleOnclick, idTag="_id" } = this.props;

    return (
      <div className="row">
        {data && data.map( item => {
          return(<div key={item[idTag]} className="col-12 col-sm-6 col-md-4 col-lg-3">
              <VillageCard item={item} handleOnclick={handleOnclick} />
          </div>)
        })}
      </div>
    );
  }
}

export default VillageCardTable;
