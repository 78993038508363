import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const ConfirmSubmit = ({isOpen, onCancel, onConfirm, message}) => {
    return ( 
        <div>
        <Modal isOpen={isOpen} toggle={onCancel} >
          <ModalHeader>โปรดยืนยัน</ModalHeader>
          <ModalBody>
             {message}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={onConfirm}>ยืนยัน</Button>{' '}
            <Button color="secondary" onClick={onCancel}>ยกเลิก</Button>
          </ModalFooter>
        </Modal>
      </div>
     );
}
 
export default ConfirmSubmit;