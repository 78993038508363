import React from "react";

const ListButton = ({ name, label, options, value, error, onChange }) => {
  // console.log(options);
  return (
    
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <ul className="list-group list-group-horizontal">
        {options.map(item => {
          return (<li
            key={item._id}
            onClick={() => onChange({currentTarget: { name: name, value: item._id }})}
            className={
              item._id === value ? "list-group-item active py-1 px-2" : "list-group-item py-1 px-2"
            }
          >
            {item.name}
          </li>)
        })}
      </ul>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};



export default ListButton;