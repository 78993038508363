import React, { Component } from 'react';
// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform'
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';

import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { toast } from 'react-toastify';

// Register the plugins
registerPlugin(
  FilePondPluginFileValidateType
  , FilePondPluginImageExifOrientation
  , FilePondPluginImagePreview
  , FilePondPluginImageCrop
  , FilePondPluginImageResize
  , FilePondPluginImageTransform
  , FilePondPluginFileEncode
  );

// Our app
class PhotoSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      files: []
    };
  }

  handleInit() {
    // console.log("FilePond instance has initialised", this.pond);
  }

  checkMimeType= type =>{
    const types = ['image/png', 'image/jpeg']
    return types.indexOf(type)>=0

}

  handleSelectFile = files => {
    console.log('handle file', files);

    if(files.length===0) {
      this.setState({files: []})

      const { notifyFileUpload } = this.props;
        if ( typeof notifyFileUpload=== 'function') {
          notifyFileUpload(files);
        }

      return
    }

    const { file } = files.find(x=>x) || {file: null};

    if ( file ) {
      // console.log(file);
      if ( this.checkMimeType(file.type)) {

        const { notifyFileUpload } = this.props;
        if ( typeof notifyFileUpload=== 'function') {
          notifyFileUpload(files);
        }
        // files.map(fileItem => fileItem.file)
        this.setState({
          files: files.map(fileItem => fileItem.file)
        });
  
      } else {
        toast.error("Invalid Image Type (Only JPEG or PNG");
        this.setState({ files: []})
      }
    }
  }


  render() {

    return (
      <React.Fragment>
        <div className="row"><div className="col">อัพโหลดรูปภาพ</div></div>
        <div className="row">
          <div className="col">
            <FilePond
              labelIdle="อัพโหลดรูปภาพ"
              ref={ref => (this.pond = ref)}
              files={this.state.files}
              maxFiles="1"
              imagePreviewHeight="500"
              imageResizeTargetWidth="500"
              imageResizeTargetHeight="500"
              imageResizeMode="cover"
              allowFileEncode="true"
              acceptedFileTypes={['image/png', 'image/jpeg']}
              styleLoadIndicatorPosition="center bottom"
              styleButtonRemoveItemPosition="center bottom"
              oninit={() => this.handleInit()}
              onupdatefiles={fileItems => this.handleSelectFile(fileItems)}
            />
          </div>
        </div>
        
      </React.Fragment>
    );
  }
}

export default PhotoSelector