import http from "./httpService";

const apiEndpoint = "/customer";

export function getCustomers(villageId) {
    const filter = (villageId) ? "/?villageId=" + villageId : "";
    // console.log(filter);
    return http.get(apiEndpoint + filter);
}

export function getCustomer(id) {
    return http.get(apiEndpoint + "/?addrindex=" + id)
}
