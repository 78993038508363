import http from "./httpService";

const apiEndpoint = "/village";

export function getVillages() {
    return http.get(apiEndpoint);
}

export function getVillage(id) {
    return http.get(apiEndpoint + "/?id=" + id)
}
  