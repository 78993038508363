import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { getVillages } from './../../services/villageService';
import VillageCardTable from '../village/villageCardTable';
import SearchBox from "../common/searchBox";
// import _ from "lodash"

class MeterWork extends Component {
    _isMounted = false;

    state = { 
        search: ""
     }

    componentDidMount() {
        this._isMounted = true;

        this.loadVillage();
    }

    componentWillUnmount() { this._isMounted=false }

    loadVillage = async() => {
        try {
            const { data: result } = await getVillages();
            if(!this._isMounted) return;

            if ( result.status && result.status==='success') {
                const { villages } = result.data;

                if(!villages) toast.error("Village data is not found")

                this.setState({ villages })
                
            } else {
                toast.error(result.message)
            }
        } catch (ex) {  toast.error(ex.message)   }
    }

    getVillages() {
        const { search, villages } = this.state;
    
        const searched = (villages && villages.length>0) ? villages.filter(item => {
          if (item.name && item.name.toLowerCase().includes(search.toLowerCase())) return true;
          if (item.id && item.id.includes(search.toLowerCase())) return true;
          return false;
        }) : [];
    
        // const sorted = _.orderBy(searched, "name", "asc");
    
        return searched;
    }


    handleSearch = query => {
        this.setState({ search: query });
    };


    render() { 
        const { search } = this.state;
        const villages = this.getVillages();

        return ( 
            <React.Fragment>
                <div className="row">
                    <div className="col-auto mr-auto mb-3">
                    <h4>บันทึกมิเตอร์</h4>
                    </div>
                    <div className="col-auto">
                        <SearchBox value={search} onChange={this.handleSearch} />
                    </div>
                </div>
                <VillageCardTable data={villages} idTag="id" />
            </React.Fragment>
         );
    }
}
 
export default MeterWork;