import React from 'react';
import { Link } from 'react-router-dom';
import defaultWaterMeter from './../../assets/default-water-meter.png';
import defaultElectricMeter from './../../assets/default-electric-meter.png';
// import moment from 'moment'

const MeterCard = ({item}) => {

    const { id, addrindex, type, name="Customer Name" } = item
    const { current, avg, lastRecordDate } = item;
    const { isLock, isFinish, isAllow, recordMonth, textRecordFinish } = item;

    const { printBillShow, printBillLabel, printBillMonth, imageurl='' } = item
    // const printBillShow = true
    // const printBillLabel = 'ใบแจ้งหนี้เดือน มีนาคม 63'
    // const printBillMonth = '202003'

    const icon = (type==='water') ? defaultWaterMeter: defaultElectricMeter
    const badgeClass = (type==='water') ? "badge-info": "badge-danger"
    const badgeText = (type==='water') ? "น้ำประปา": "ไฟฟ้า"

    return ( 
        <React.Fragment>
            <div className="card mb-3">
                <div className="row no-gutters align-items-top p-3">
                    <div className="col-auto text-center pt-2">
                        <img src={icon} style={{width: "50px", backgroundColor: "#EEEEEE66"}} className="card-img-top rounded-circle p-1" alt="..." />
                        <h5><span className={"badge "+badgeClass}>{badgeText}</span></h5>
                    </div>
                    <div className="col-auto">
                        <div className="card-body text-black py-2">
                            <h5 className="card-title d-inline">
                                {name}
                            </h5>
                            <p className="card-text">
                                เลขที่มิเตอร์ <strong>{id}</strong><br />
                                วันที่บันทึกครั้งหลังสุด <strong>{lastRecordDate}</strong><br />
                                เลขมิเตอร์ครั้งหลังสุด <strong>{current}</strong><br />
                                จำนวนหน่วยใช้งานเฉลี่ย <strong>{avg}</strong>
                            </p>
                            <div className="row no-gutters">
                                <div className="col-auto">
                                {printBillShow && <p className="card-text">
                                <Link to={"/bill/" + id + "/?month=" + printBillMonth} className="btn btn-info">{printBillLabel}</Link>
                                </p>}
                                </div>
                                <div className="col-auto mx-2">
                                {imageurl && <button type="button" className="btn btn-secondary" onClick={() => window.open(imageurl, '_blank')}>ภาพถ่าย</button>}
                                </div>
                            </div>
                            
                            
                        </div>
                    </div>
                </div>
                <div className="card-footer text-right">
                    {isLock && <span>ระบบไม่เปิดให้บันทึกข้อมูลในขณะนี้</span>}
                    {!isLock && isFinish && <span className="text-primary"><strong>{textRecordFinish}</strong></span>}
                    {!isLock && isAllow && !isFinish && <Link to={"/meter/" + id + "/record/new/?month=" + recordMonth + "&addrindex=" + addrindex} className="btn btn-primary ml-3">เพิ่มบันทึก</Link>}
                    {/* {<Link to={"/meter/" + id + "/record/new/?month=" + recordMonth + "&addrindex=" + addrindex} className="btn btn-primary ml-3">เพิ่มบันทึก</Link>} */}
                    {!isLock && isAllow && isFinish && <Link to={"/meter/" + id + "/record/new/?month=" + recordMonth + "&addrindex=" + addrindex} className="btn btn-warning ml-3">แก้ไข</Link>}
                </div>
            </div>
        </React.Fragment>
     );
}
 
export default MeterCard;