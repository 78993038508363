import http from "./httpService";

const apiEndpoint = "/meter";

export function getMeters(addrindex) {
    const filter = (addrindex) ? "/?addrindex=" + addrindex : "";
    return http.get(apiEndpoint + filter);
}

export function addMeterRecord(doc) {
    return http.post("/readmeter", doc);
    
}
