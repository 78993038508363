import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
// import _ from 'lodash';
// import moment from 'moment';
// import FontAwesome from 'react-fontawesome';
// import ConfirmDelete from "../common/confirmDelete";
import { toast } from "react-toastify";
// import { Link } from 'react-router-dom';
import moment from 'moment'

// import MyCalendar from '../common/calendar';
// import LineSelector from './lineSelector';
import PhotoSelector from './photoSelector';
import { getMeters, addMeterRecord } from './../../services/meterService';
import querystring from 'query-string';
import ConfirmSubmit from "../common/confirmSubmit";
import PopupAlert from './../common/popupAlert';

moment.locale('TH');

class MeterForm extends Form {
  _isMounted = false;

  state = {
    data: {
      meterNumber: "",
      photos: []
    },
    recordMonth: "",
    meter: {},
    errors: {},
    askConfirm: {enable: false, message: ""},
    askAlert: {enable: false, message: ""}
  };

  getAllData() {
    return {...this.state.data}
  }

  getAllSchema() {
    return {...this.schema}
  }

  schema = {
    meterNumber: Joi.number().min(0).max(99999999).required().label("เลขมิเตอร์"),
    photos: Joi.array().min(0)
  };

  schemaExtend = {
  };

  async componentDidMount() {
    this._isMounted = true;

    const query = querystring.parse(this.props.location.search);
    if(query && query.month) this.setState({recordMonth: query.month})
    if(query && query.urlBack) this.setState({urlBack: query.urlBack})
    if(query && query.addrindex) this.setState({addrindex: query.addrindex})

    this.loadMeter();

  }

  componentWillUnmount() { this._isMounted = false;  }

  loadMeter = async() => {
    try {
        const id = this.props.match.params.id;
        if (!id) return;

        const query = querystring.parse(this.props.location.search);
        if(!query || !query.addrindex) return;

        const { addrindex } = query;

        const { data: result } = await getMeters(addrindex);
        if(!this._isMounted) return;

        if ( result.status && result.status==='success') {
            const { meters } = result.data;

            if(!meters) toast.error("Meter data is not found")
            const getMeter = meters.find(x=>x.id===id);

            if(!getMeter) toast.error("Meter data is not found")

            this.setState({ id: id, meter: getMeter })
            
        } else {
            toast.error(result.message)
        }
    } catch (ex) {  toast.error(ex.message)   }
  }

  notifyPhotoSelector = files => {
    const data = {...this.state.data}
    data.photos = files

    // console.log(files);
    // files.map(async x=> {
    //   try {
    //     // console.log(x)
    //     const getBase64 = await x.getFileEncodeDataURL();
    //     console.log(getBase64)
    //   } catch(err) {
    //     // console.log(err);
    //   }
      
    //   return x
    // })

    console.log(data);
    
    this.setState({ data })
  }

  doSubmit = async (confirm=false) => {
    try {
      const {id, recordMonth} = this.state;
      const { meterNumber, photos } = this.state.data;
      const { current, min, max } = this.state.meter;

      

      if(!recordMonth) return toast.error("ไม่พบข้อมูล record month")

      if ( id && meterNumber && photos && recordMonth) {

        var parsed = parseInt(current);
        const currentMeter = (isNaN(parsed)) ? 999999 : parsed

        if( meterNumber< currentMeter && photos.length===0) {
          this.setState({askAlert: {enable: true, message: "ต้องการรูปถ่ายประกอบ\nเลขมิเตอร์น้อยกว่าครั้งก่อนหน้า"}})
          return
        }

        const currentUsage = meterNumber-currentMeter
        if( (currentUsage > Math.floor(max) || currentUsage< Math.floor(min)) && photos.length===0) {
          this.setState({askAlert: {enable: true, message: "ต้องการรูปถ่ายประกอบ\nจำนวนใช้งานไม่อยู่ในช่วงที่เหมาะสม"}})
          return
        }

        if( meterNumber< currentMeter && !confirm) {
          this.setState({ askConfirm: {enable: true, message: "บันทึกเลขมิเตอร์น้อยกว่าครั้งก่อนหน้า"}})
          return
        }
        

        const getPhoto = photos.find(x=>x);
        let photoBase64=""
        try {
          photoBase64 = (getPhoto) ? await getPhoto.getFileEncodeDataURL() : ""
        } catch(err) {}

        // if(!photoBase64) return toast.error("ไม่พบข้อมูลรูปภาพ")
        // console.log('base64 original',photoBase64);
        if(photoBase64.includes('data:image/png;base64,')) photoBase64= photoBase64.replace("data:image/png;base64,", "");
        if(photoBase64.includes('data:image/jpg;base64,')) photoBase64= photoBase64.replace("data:image/jpg;base64,", "");
        if(photoBase64.includes('data:image/jpeg;base64,')) photoBase64= photoBase64.replace("data:image/jpeg;base64,", "");
        // console.log('base64 trim', photoBase64)

        const doc = { meterid: id, realmeter: meterNumber, month: recordMonth, imagedata: photoBase64  };
    
        const {data: result} = await addMeterRecord(doc);

        if (result.status==='success') {
          toast.success("บันทึกมิเตอร์สำเร็จ");

          const { urlBack, addrindex } = this.state;
          const urlGoBack = (urlBack) ? "/customer/" + urlBack : "/customer/" + encodeURIComponent(addrindex)

          // console.log(encodeURIComponent(urlGoBack))
          // encodeURIComponent(urlGoBack)

          setTimeout(this.props.history.replace(urlGoBack), 1000);
        } else {
          toast.error(result.message)
        }

      } else { toast.error("โปรดกรอกข้อมูลให้ครบถ้วน")  }
    } catch(err) { toast.error("บันทึกข้อมูลไม่สำเร็จ\n" + err.message)  }
  }

  handleConfirmSubmit = () => {
    this.setState({askConfirm: { enable: false, message: ""}})
    this.doSubmit(true);
  }
  handleCancleSubmit = () => {
    this.setState({askConfirm: { enable: false, message: ""}})
  }

  handlePopupAlertClick = () => {
    this.setState({askAlert: { enable: false, message: ""}})
  }


  render() {
    
    const { askConfirm, askAlert } = this.state;
    const { id, name, type, current, avg, min, max, lastRecordDate, METER_MULTIPLY=1 } = this.state.meter;

    const multiplier = isNaN(METER_MULTIPLY) ? 1 : METER_MULTIPLY
    // console.log(this.state.meter);
    if(!id) return (<div></div>)

    const { meterNumber } = this.state.data;
    const parsedCurrent = parseInt(current);
    const parsed = parseInt(meterNumber);
    const currentUsage = (isNaN(parsed) || isNaN(parsedCurrent)) ? "-" : (parsed>=current) ? ((parsed-parsedCurrent)*multiplier) : "เลขมิเตอร์น้อยกว่าครั้งก่อน"

    const headerName = (type==="water") ? "บันทึกมิเตอร์น้ำประปา" : (type==="electric") ? "บันทึกมิเตอร์ไฟฟ้า" : "บันทึกมิเตอร์(ไม่ทราบประเภท)"
    const headerClass = (type==="water") ? "card-header bg-info text-white" : (type==="electric") ? "card-header bg-danger text-white" : "card-header bg-secondary text-white" 

    return (
      <React.Fragment>
        <PopupAlert isOpen={askAlert.enable} onOk={this.handlePopupAlertClick} message={askAlert.message}  />
        <ConfirmSubmit isOpen={askConfirm.enable} onConfirm={this.handleConfirmSubmit} onCancel={this.handleCancleSubmit} message={askConfirm.message} />
        <div className="container mb-4">
          <form action="" autoComplete="off" onSubmit={this.handleSubmit}>
            <div className="card">
              <div className={headerClass}>
                  <strong>{headerName} : {name}</strong>
              </div>
              <div className="card-body text-black">
                  <p className="card-text">
                      เลขที่มิเตอร์ <strong>{id}</strong><br />
                      วันที่บันทึกครั้งหลังสุด <strong>{lastRecordDate}</strong><br />
                      เลขมิเตอร์ครั้งหลังสุด <strong>{current}</strong><br />
                      จำนวนหน่วยใช้งานเฉลี่ย <strong>{avg}</strong>
                  </p>
              </div>
              <div className="card-body">
                  <span className="card-text">
                    {this.renderInputNumberOnly("meterNumber", "เลขมิเตอร์ครั้งนี้")}
                    <span>จำนวนหน่วยใช้งาน <span className="badge badge-pill badge-secondary">{currentUsage}</span></span>
                    <span className="ml-4 text-muted"><strong>(ตัวคูณหน่วยมิเตอร์ {multiplier})</strong></span>
                    <span className="ml-1 text-muted"><strong>(ค่าเหมาะสม {min}-{max})</strong></span>
                    
                    <div className="mt-2"><PhotoSelector notifyFileUpload={this.notifyPhotoSelector} /></div>
                  </span>
              </div>
              <div className="card-footer text-right">
                {this.renderButton("บันทึก")}
                <div className="btn btn-secondary ml-3" onClick={() => this.props.history.goBack()}>ยกเลิก</div>
              </div>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

export default MeterForm;
