import React, { Component } from "react";
import MeterCard from "./meterCard";

class MeterCardTable extends Component {

  render() {
    const { data, idTag="_id" } = this.props;

    return (
      <div className="row">
        {data && data.map( item => {
          return(<div key={item[idTag]} className="col-12 col-md-6">
              <MeterCard item={item} />
          </div>)
        })}
      </div>
    );
  }
}

export default MeterCardTable;
