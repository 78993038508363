import 'flatpickr/dist/themes/material_blue.css'
// import "flatpickr/dist/themes/dark.css";

import React from 'react';
import Flatpickr from "react-flatpickr";
 
const TimePicker = ({ name, label, error, onChange, value, ...rest }) => {
  // console.log(options);
  return (
    <div className="form-group">
        <label htmlFor={name}>{label}</label>
        <Flatpickr className="form-control"
          id={name}
          name={name}
          onChange={(_,str) => onChange({ currentTarget: { name: name, value: str}})}
          options={{
            enableTime: true,
            noCalendar: true,
            dateFormat: "H:i",
            time_24hr: true
          }}
          value={value}
        />
        {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default TimePicker