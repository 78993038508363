import React from 'react';
import { Link } from 'react-router-dom';
import defaultImageHouse from './../../assets/default-house.png';
import imageWaterMeter from './../../assets/default-water-meter.png';
import imageElectricMeter from './../../assets/default-electric-meter.png';

const CustomerCard = ({item, village}) => {

    const {  addrindex, name } = item
    const { waterMeter, waterMeterFinish, electricMeter, electricMeterFinish} = item;
    const customerId = encodeURIComponent(addrindex)
    // console.log(encodeURIComponent(addrindex))
    // console.log(customerId)

    // console.log(waterMeter);

    // const waterMeter = Math.floor(Math.random() * 3) + 1
    // const electricMeter = Math.floor(Math.random() * 3) + 1

    const percentWater = Math.round(waterMeterFinish * 100 / waterMeter)
    const percentElectric = Math.round(electricMeterFinish * 100 / electricMeter)

    const cssWaterNum = (percentWater>=100) ? "notify-badge bg-success text-white" : "notify-badge"
    const cssElectricNum = (percentElectric>=100) ? "notify-badge bg-success text-white" : "notify-badge"


    return ( 
        <React.Fragment>
            <div className="card mb-3">
                <div className="row no-gutters align-items-center justify-content-start px-2">
                    <div className="col-auto p-1">
                        <img src={defaultImageHouse} style={{width: "50px", backgroundColor: "#EEEEEE66"}} className="card-img-top rounded-circle p-1" alt="..." />
                    </div>
                    <div className="col-auto">
                        <div className="card-body text-black py-2">
                            <h5 className="card-title d-inline">
                                {name}
                            </h5>
                        </div>
                    </div>
                    <div className="col-auto mx-auto"></div>
                    {waterMeter!==0 && <div className="col-auto px-2 mb-2">
                        <div className="notify-item">
                            <span className={cssWaterNum}>{waterMeter}</span>
                            <img src={imageWaterMeter} style={{width: "30px"}} alt="water meter" />
                        </div>
                    </div>}
                    {electricMeter!==0 && <div className="col-auto px-2 mb-2">
                        <div className="notify-item">
                            <span className={cssElectricNum}>{electricMeter}</span>
                            <img src={imageElectricMeter} style={{width: "30px"}} alt="electric meter" />
                        </div>
                    </div>}
                </div>
                <Link className="stretched-link text-dark" style={{textDecoration: 'inherit'}} to={`/customer/${customerId}`}></Link>
            </div>
        </React.Fragment>
     );
}
 
export default CustomerCard;