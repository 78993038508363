import React, { Component } from 'react';
import { toast } from 'react-toastify';
import MeterCardTable from './../meter/meterCardTable';
import { getMeters } from './../../services/meterService';
import { Link } from 'react-router-dom';
// import querystring from 'query-string';
import { getCustomer } from '../../services/customerService';
import { getVillage } from '../../services/villageService';

class Customer extends Component {
    _isMounted = false;

    state = { 
        villageId: "",
        villageName: "",
        customerId: "",
        customer: ""
     }

    componentDidMount() {
        this._isMounted = true;

        // const query = querystring.parse(this.props.location.search);
        // if(query) {
        //     this.setState({ villageId: query.villageId, villageName: query.village, customer: query.customer})
        // }

        this.loadCustomer()
        this.loadMeter();
    }

    componentWillUnmount() { this._isMounted=false }

    loadCustomer = async() => {
        try {
            const id = this.props.match.params.id;
            if (!id) return;

            const { data: result } = await getCustomer(decodeURIComponent(id));
            if(!this._isMounted) return;

            if ( result.status && result.status==='success') {
                const { customer } = result.data;

                if(customer) this.setState({ villageId: customer.id, customerId: decodeURIComponent(id), customer: customer.name })

                const { id: villageId } = customer;
                if(villageId) {
                    const { data: result } = await getVillage(villageId);
                    if(!this._isMounted) return;

                    if ( result.status && result.status==='success') {
                        const { village } = result.data;
                        if(village) this.setState({ villageName: village.name })
                    } else {
                        toast.error(result.message)
                    }
                }
                
            } else {
                toast.error(result.message)
            }
        } catch (ex) {  toast.error(ex.message)   }
    }

    loadMeter = async() => {
        try {
            const id = this.props.match.params.id;
            if (!id) return;

            const { data: result } = await getMeters(decodeURIComponent(id));
            if(!this._isMounted) return;

            if ( result.status && result.status==='success') {
                const { meters } = result.data;

                if(!meters) toast.error("Village data is not found")

                this.setState({ meters })
                
            } else {
                toast.error(result.message)
            }
        } catch (ex) {  toast.error(ex.message)   }
    }

    handleVillageCardClick = async() => {

    }


    render() { 
        const { meters } = this.state;
        const { villageId, villageName, customerId, customer } = this.state

        return ( 
            <React.Fragment>
                <div className="row align-item-center">
                    <div className="col-auto mb-3">
                        <h4>บันทึกมิเตอร์</h4>
                    </div>
                    <div className="col-auto mb-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb bg-light py-1">
                                <li className="breadcrumb-item"><Link to="/meterwork" >หมู่บ้าน</Link></li>
                                <li className="breadcrumb-item"><Link to={`/village/${villageId}/?customerScroll=${customerId}`} >{villageName}</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">{customer}</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <MeterCardTable data={meters} idTag="id" />
            </React.Fragment>
         );
    }
}
 
export default Customer;