import React from "react";

const FlowButton = ({ name, label, value, options, onSelect, error, textProperty, valueProperty, ...rest }) => {

  return (
    <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <div>
        {options && options.map(item => {
          return (
            <button key={item._id} type="button" 
            className={ value && value.filter(x => x[valueProperty]===item[valueProperty]).length>0 ? "btn btn-primary mr-2 mb-2" : "btn btn-outline-dark mr-2 mb-2" }
            onClick={() => onSelect({name: name, item: item})}>{item[textProperty]}</button>
          )
        })}
      </div>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

FlowButton.defaultProps = {
  textProperty: "name",
  valueProperty: "_id"
};

export default FlowButton;
