import React, { Component } from 'react';
import { toast } from 'react-toastify';
import CustomerCardTable from './../customer/customerCardTable';
import { getCustomers } from './../../services/customerService';
import SearchBox from "../common/searchBox";
// import _ from "lodash"
import querystring from 'query-string';
import { Link } from 'react-router-dom';
import { getVillage } from '../../services/villageService';

class Village extends Component {
    _isMounted = false;

    state = { 
        search:"",
        customers: [],
        villageId: "",
        villageName: ""
     }

    componentDidMount() {
        this._isMounted = true;

        const query = querystring.parse(this.props.location.search);
        if(query && query.customerScroll) this.setState({customerScroll: query.customerScroll})

        this.loadVillage();
        this.loadCustomers();
    }

    componentWillUnmount() { this._isMounted=false }

    loadVillage = async() => {
        try {
            const id = this.props.match.params.id;
            if (!id) return;

            const { data: result } = await getVillage(id);
            if(!this._isMounted) return;

            if ( result.status && result.status==='success') {
                const { village } = result.data;

                if(village) this.setState({ villageName: village.name })
            } else {
                toast.error(result.message)
            }
        } catch (ex) {  toast.error(ex.message)   }
    }

    loadCustomers = async() => {
        try {
            const id = this.props.match.params.id;
            if (!id) return;

            const { data: result } = await getCustomers(id);
            if(!this._isMounted) return;

            if ( result.status && result.status==='success') {
                const { customers } = result.data;

                if(!customers) toast.error("Customer data is not found")

                this.setState({ villageId: id, customers: customers })
                
            } else {
                toast.error(result.message)
            }
        } catch (ex) {  toast.error(ex.message)   }
    }

    getCustomers = () => {
        const { search, customers } = this.state;
    
        const searched = (customers && customers.length>0) ? customers.filter(item => {
          if (item.name && item.name.toLowerCase().includes(search.toLowerCase())) return true;
          if (item.customerId && item.customerId.includes(search.toLowerCase())) return true;
          return false;
        }) : [];
    
        // const sorted = _.orderBy(searched, "name", "asc");
    
        return searched;
      };


    handleSearch = query => {
        this.setState({ search: query });
    };


    render() { 
        const { search, villageName, customerScroll } = this.state;
        const customers = this.getCustomers();

        const village = { id: this.state.villageId, name: villageName}

        // if(customerScroll && customers.length>0) {
            
        //     // scroller.scrollTo("testscroll")
        // }

        return ( 
            <React.Fragment>
                <div className="row justify-content-start">
                    <div className="col-auto mb-3">
                    <h4>บันทึกมิเตอร์</h4>
                    </div>
                    <div className="col-auto mb-3">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb bg-light py-1">
                                <li className="breadcrumb-item"><Link to="/meterwork" >หมู่บ้าน</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">{villageName}</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="col-auto ml-auto">
                        <SearchBox value={search} onChange={this.handleSearch} />
                    </div>
                </div>
                {customers.length===0 && <div className="alert alert-secondary" role="alert">ไม่มีข้อมูล</div>}
                <CustomerCardTable data={customers} village={village} idTag="customerId" scroll={customerScroll} />
            </React.Fragment>
         );
    }
}
 
export default Village;