import React from 'react';
import { Link } from 'react-router-dom';
import defaultImageVillage from './../../assets/default-village.png';

const VillageCard = ({item}) => {

    const { id, name } = item
    const {waterMeter, waterMeterFinish, electricMeter, electricMeterFinish} = item;

    const percentWater = (waterMeter===0 || waterMeterFinish===0 ) ? 0 : Math.round(waterMeterFinish*100/waterMeter);
    const percentElectric = (electricMeter===0 || electricMeterFinish===0) ? 0 : Math.round(electricMeterFinish*100/electricMeter);
    // const percentWater = Math.floor(Math.random() * 70) + 20
    // const percentElectric = Math.floor(Math.random() * 70) + 20

    // const random = (customerAll==="0") ? 0 : Math.floor(Math.random() * Math.floor(customerAll))
    // const percent = (customerAll==="0") ? 0 : Math.round(random*100/customerAll);
    // console.log(waterMeter, percentWater);

    return ( 
        <React.Fragment>
            <div className="card mb-3">
                <img src={defaultImageVillage} className="card-img-top m-0 p-0" alt="..." />
                <div className="card-body py-2">
                    <div className="progress my-2" style={{height: "25px"}}>
                        <div className={`progress-bar bg-info`} role="progressbar" style={{width: `${percentWater}%`}} 
                            aria-valuenow={percentWater} aria-valuemin="0" aria-valuemax="100"><span className="text-dark">{percentWater}% ({waterMeterFinish}/{waterMeter})</span></div>
                    </div>
                    <div className="progress my-2" style={{height: "25px"}}>
                        <div className={`progress-bar bg-danger`} role="progressbar" style={{width: `${percentElectric}%`}} 
                            aria-valuenow={percentElectric} aria-valuemin="0" aria-valuemax="100"><span className="text-dark">{percentElectric}% ({electricMeterFinish}/{electricMeter})</span></div>
                    </div>
                </div>
                <div className="card-body bg-secondary text-center text-dark py-2">
                    <h5 className="card-title d-inline">
                        <Link className="stretched-link text-white" style={{textDecoration: 'inherit'}} to={`/village/${id}`}>{name}</Link>
                    </h5>
                </div>
            </div>
        </React.Fragment>
     );
}
 
export default VillageCard;