import http from "./httpService";

// const apiEndpoint = "/bill";


export function GetMeterBillMonth(addrindex, month) {
    return http.get(`/bill/?meterid=${addrindex}&month=${month}`);
}

export function confirmPrint({ARID, month}) {
    return http.post('/ConfirmPrint', {ARID, month})
}