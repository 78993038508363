import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import auth from "../../services/authService";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";

class LoginForm extends Form {
  //   username = React.createRef();

  state = {
    data: { username: "", password: "" },
    errors: {}
  };

  schema = {
    username: Joi.string()
      .required()
      .label("Username"),
    password: Joi.string()
      .required()
      .label("Password")
  };

  getAllData() {
    return {...this.state.data}
  }

  getAllSchema() {
    return {...this.schema}
  }

  doSubmit = async () => {
    try {
      const { data } = this.state;
      const result = await auth.login(data.username, data.password);

      if ( result === "OK") {
        const { state } = this.props.location;

        window.location = state ? state.from.pathname : "/"; //Full refresh
      } else {
        toast.error(result);
      }
      
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        toast.error(ex.response.data);
      }
    }
  };

  render() {
    // console.log(auth.getCurrentUser())
    if (auth.getCurrentUser()) return <Redirect to="/" />;

    return (
      <div>
          <div className="row justify-content-center mt-5 mb-3">
            <div className="col-10 col-sm-8 col-md-7 col-lg-5 mx-2">
            <h3>Login</h3>
            </div>
          </div>
        
        <form action="" onSubmit={this.handleSubmit}>
          <div className="row justify-content-center">
            <div className="col-10 col-sm-8 col-md-7 col-lg-5 mx-2">
            {this.renderInput("username", "Username")}
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-10 col-sm-8 col-md-7 col-lg-5 mx-2">
            {this.renderInput("password", "Password", "password")}
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-10 col-sm-8 col-md-7 col-lg-5 mx-2">
            {this.renderButton("Login")}
            </div>
          </div>

          
        </form>
      </div>
    );
  }
}

export default LoginForm;
