import React from "react";

const Radio = ({ name, label, error, ...rest }) => {
  return (
    <div className="d-inline">
      <input {...rest} name={name} id={name}  /><label htmlFor={name}>{label}</label>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
};

export default Radio;
