import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import auth from "./services/authService";

import NavBar from "./components/menu/navBar";
// import Live from "./components/live/live";
// import Work from "./components/work/work";
// import Templates from "./components/template/templates";
import NotFound from "./components/notFound";
// import Staff from "./components/staff/staffs";

import LoginForm from "./components/session/loginForm";
import Logout from "./components/session/logout";
import ProtectedRoute from "./components/common/protectedRoute";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";
// import PlanForm from "./components/plan/planForm";
// import Plans from "./components/plan/plan";
// import StaffForm from "./components/staff/staffForm";
// import Branches from "./components/branch/branches";
// import AreaForm from "./components/branch/areaForm";
// import Report from "./components/report/report";
// import BranchForm from "./components/branch/formAreaCreate";
// import LineRoom from "./components/line/lineRooms";
// import LineRoomForm from "./components/line/lineRoomForm";
// import EventForm from "./components/event/eventForm";
// import Event from "./components/event/event";
import MeterWork from './components/meter/meterWork';
import Village from './components/village/village';
import Customer from './components/customer/customer';
import MeterForm from './components/meter/meterForm'
import MeterBillMonthly from './components/bill/meterMonth';
import PrintExample from './components/bill/printExample';

class App extends Component {
  state = {};

  componentDidMount() {
    const user = auth.getCurrentUser();
    this.setState({ user });
  }

  render() {
    const { user } = this.state;

    return (
      <React.Fragment>
        <ToastContainer />
        <NavBar user={user} />
        <main className="container-fluid">
          <Switch>
            <ProtectedRoute path="/meter/:id/record/new" exact render={props => <MeterForm {...props} user={user} />} />
            <ProtectedRoute path="/village/:id" exact render={props => <Village {...props} user={user} />} />
            <ProtectedRoute path="/customer/:id" exact render={props => <Customer {...props} user={user} />} />
            <ProtectedRoute path="/meterwork" exact render={props => <MeterWork {...props} user={user} />} />
            <ProtectedRoute path="/bill/:id" exact render={props => <MeterBillMonthly {...props} user={user} />} />
            <ProtectedRoute path="/print" exact component={PrintExample} />
            {/* <ProtectedRoute path="/live" exact render={props => <Live {...props} user={user} />} /> */}

            {/* <Route path="/event/share/:id" exact component={Event} /> */}
            {/* <ProtectedRoute path="/live" exact render={props => <Live {...props} user={user} />}  /> */}
            {/* <ProtectedRoute path="/event/new" exact component={EventForm} /> */}
            {/* <ProtectedRoute path="/event/:id" exact render={props => <Event {...props} user={user} />} /> */}
            {/* <Route path="/work/:id" component={Work} /> */}

            {/* <ProtectedRoute path="/plan" exact render={props => <Plans {...props} user={user} />} />
            <ProtectedRoute path="/plan/:id" exact component={PlanForm} /> */}
            {/* <ProtectedRoute path="/template" exact render={props => <Templates {...props} user={user} />} /> */}
            {/* <ProtectedRoute path="/branch" exact  render={props => <Branches {...props} user={user} />} />
            <ProtectedRoute path="/branch/:id" exact component={AreaForm} /> */}
            {/* <ProtectedRoute path="/line" exact  render={props => <LineRoom {...props} user={user} />} />
            <ProtectedRoute path="/line/:id" exact component={LineRoomForm} /> */}
            {/* <ProtectedRoute path="/staff" exact render={props => <Staff {...props} user={user} />} /> */}
            {/* <ProtectedRoute path="/staff/:id" exact component={StaffForm} /> */}
            
            <Route path="/login" component={LoginForm} />;
            <ProtectedRoute path="/logout" component={Logout} />;
            <Route path="/not-found" component={NotFound} />
            <Redirect from="/" exact to="/meterwork" />
            <Redirect to="/not-found" />
          </Switch>
        </main>
      </React.Fragment>
    );
  }
}

export default App;
